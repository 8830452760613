
import { lockScroll } from '~/mixins/lockScroll';

export default {
  name: 'modal-mobile',
  mixins: [lockScroll],
  data() {
    return {
      offset: 0,
      withLock: true,
    };
  },
  methods: {
    closeWindow(e) {
      e.preventDefault();
      this.$emit('close');
    },
  },
};
