
export default {
  name: 'base-navbar',
  props: {
    dark: Boolean,
    fixed: Boolean,
  },
  data() {
    return {
      isOpen: false,
      scrollPosition: 0,
      links: [
        {name: 'О нас', url: '/#about'},
        {name: 'Программы', url: '/#programs'},
        {name: 'Мастера', url: '/#masters'},
        {name: 'Акции', url: '/#offers'},
        {name: 'Вакансии', url: '/jobs'},
      ],
    };
  },
  computed: {
    isBg() {
      return this.scrollPosition && this.currentVh && this.scrollPosition > this.currentVh - 105;
    },
  },
  watch: {
    '$route': {
      handler () {
        this.isOpen = false;
      },
      deep: true,
    },
  },
  mounted() {
    if (this.fixed) window.addEventListener('scroll', this.updateScroll);
  },
  beforeDestroy() {
    if (this.fixed) window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
