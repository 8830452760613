import { render, staticRenderFns } from "./modal-mobile.vue?vue&type=template&id=0b7811d8&"
import script from "./modal-mobile.vue?vue&type=script&lang=js&"
export * from "./modal-mobile.vue?vue&type=script&lang=js&"
import style0 from "./modal-mobile.vue?vue&type=style&index=0&id=0b7811d8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconX: require('/vercel/path0/src/components/icon/icon-x.vue').default})
