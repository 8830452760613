/* eslint-disable no-useless-escape */
export default {
  render(createElement) {
    const vm = this;
    let tagName;
    const elementOptions = {
      class: {
        'button': true,
        'button--light': this.light,
        'button--up': this.up,
        'button--outline': this.outline || false,
        'button--outline-light': this.outline && this.light,
        'button--white': this.white,
        'button--disabled': this.disabled || false,
      },
      on: {
        click(e) {
          e.preventDefault();
          vm.$emit('click', e);
        },
        enter(e) {
          vm.$emit('enter', e);
        },
      },
      attrs: {
        tabindex: '1',
      },
    };

    if (this.href && this.isRouterLink) {
      tagName = 'router-link';
      Object.assign(elementOptions.attrs, { to: this.href });
    } else if (this.href) {
      tagName = 'a';
      Object.assign(elementOptions.attrs, { href: this.href });
    } else {
      Object.assign(elementOptions.attrs, { type: this.type });
      tagName = 'button';
    }

    return createElement(tagName, elementOptions, this.$slots.default);
  },
  name: 'base-button',
  props: {
    href: {
      type: [String, Object],
      default: null,
    },
    type: {
      type: String,
      default: 'button',
    },
    up: Boolean,
    light: Boolean,
    outline: Boolean,
    disabled: Boolean,
    white: Boolean,
  },
  computed: {
    isRouterLink() {
      const URL = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      return !URL.test(this.href);
    },
  },
};
