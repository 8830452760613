
export default {
  name: 'base-logo',
  props: {
    red: Boolean,
    light: Boolean,
    dark: Boolean,
    full: Boolean,
  },
  computed: {
    height() {
      return this.full ? '93.1' : '77.3';
    },
  },
};
